import {
  LOAD_INDIVIDUALS_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
} from "../actions/individuals.actions";
import { TAction } from "../store";
import {
  TLoadIndividualsSuccessPayload,
  TState,
  TUpdateProfileSuccessPayload,
} from "../types/individuals.types";

//----------------------------------------------------------------------------------------------------------------------
const initialState: TState = {};
//----------------------------------------------------------------------------------------------------------------------

const individualsReducers = (
  state = initialState,
  { type, payload }: TAction<any>
): TState => {
  switch (type) {
    case LOAD_INDIVIDUALS_SUCCESS: {
      const { data } = payload as TLoadIndividualsSuccessPayload;
      return {
        ...state,
        // @ts-ignore
        items: data.items,
        total: data.total || undefined,
        nextToken: data.nextToken || undefined,
      };
    }
    case UPDATE_PROFILE_SUCCESS: {
      const { data } = payload as TUpdateProfileSuccessPayload;
      if (data.details?.accountType !== "individual") return state;

      return {
        ...state,
        // @ts-ignore
        items: state.items?.map((item) => (item.id !== data.id ? item : data)),
      };
    }
    default:
      return state;
  }
};

export default individualsReducers;
