import { Profile } from "../types/DBTypes";

type TProfileHelperFunction<T> = (profile: Profile) => T;
export const getProfileIsVerified: TProfileHelperFunction<boolean> = ({
  employers,
  id,
}) => {
  return !!employers?.items?.some(
    (emp) => emp?.profileId === id && emp?.confirmed
  );
};

export const getLocation: TProfileHelperFunction<string> = ({ location }) => {
  if (!location) return "";
  const { city, country } = location;
  return [city, country].filter((x) => x).join(", ");
};

export const getBusinessLocation: TProfileHelperFunction<string> = ({
  business,
}) => {
  if (!business?.details?.location) return "";
  const { city, country } = business.details?.location;
  return [city, country].filter((x) => x).join(", ");
};

export const getEmployeeSlug: TProfileHelperFunction<string | null> = ({
  business,
  slug,
}) => {
  if (!business?.ownerProfile?.slug) return null;
  return `${slug}/${business.ownerProfile.slug}`;
};
