import { put, takeLatest, select } from "redux-saga/effects";
import {
  CREATE_BRAND,
  createBrandSuccess,
  DELETE_BRAND,
  deleteBrandSuccess,
  LOAD_BRANDS,
  LOAD_BRANDS_MORE,
  loadBrandsMoreSuccess,
  loadBrandsSuccess,
  UPDATE_BRAND,
  updateBrandSuccess,
} from "../actions/brand.actions";
import { API } from "aws-amplify";
import { searchBrands } from "../../graphql/queries";
import { TAction } from "../store";
import {
  TCreateBrandPayload,
  TDeleteBrandPayload,
  TLoadBrandsPayload,
  TUpdateBrandPayload,
} from "../types/brand.types";
import {
  CreateBrandInput,
  SearchBrandsQueryVariables,
  UpdateBrandInput,
} from "../../types/DBTypes";
import { createBrand, deleteBrand, updateBrand } from "../../graphql/mutations";
import { showToast } from "../actions/ui.actions";
import { getBrandsNextToken } from "../selectors/brand.selectors";
import safe from "../utils/safe";

const LIMIT = 96;
function* loadBrandsSaga({ payload }: TAction<TLoadBrandsPayload>) {
  const { categories } = payload;

  const variables: SearchBrandsQueryVariables = {};

  if (categories?.length) {
    variables.filter = {
      or: categories?.map((category) => ({
        productType: { eq: category },
      })),
    };
  }

  variables.limit = LIMIT;
  const { data } = yield API.graphql({
    query: searchBrands,
    variables,
  });

  yield put(loadBrandsSuccess({ data: data?.searchBrands }));
}

function* loadBrandsMoreSaga() {
  // @ts-ignore
  const nextToken = yield select(getBrandsNextToken);

  if (!nextToken) yield put(showToast({ text: "All brands loaded" }));

  const { data } = yield API.graphql({
    query: searchBrands,
    variables: {
      nextToken,
      limit: LIMIT,
    },
  });

  yield put(loadBrandsMoreSuccess({ data: data?.searchBrands }));
}

function* updateBrandSaga({ payload }: TAction<TUpdateBrandPayload>) {
  const { id, name, logo, active, productType } = payload;

  const input: UpdateBrandInput = { id };
  if (name) input.name = name;
  if (logo) input.logo = logo;
  if (active) input.active = active;
  if (productType) input.productType = productType;

  const { data } = yield API.graphql({
    query: updateBrand,
    variables: { input },
  });

  yield put(updateBrandSuccess({ data: data?.updateBrand }));
  yield put(showToast({ text: "Brand updated successfully" }));
}

function* createBrandSaga({ payload }: TAction<TCreateBrandPayload>) {
  const { name, logo, active, productType } = payload;

  const input: CreateBrandInput = { name, logo, active, productType };

  const { data } = yield API.graphql({
    query: createBrand,
    variables: { input },
  });

  yield put(createBrandSuccess({ data: data?.createBrand }));
  yield put(showToast({ text: "Brand created successfully" }));
}

function* deleteBrandSaga({ payload }: TAction<TDeleteBrandPayload>) {
  const { id } = payload;

  const { data } = yield API.graphql({
    query: deleteBrand,
    variables: { input: { id } },
  });

  yield put(showToast({ text: "Brand deleted successfully" }));
  yield put(deleteBrandSuccess({ data: data?.deleteBrand }));
}

export default function* brandSagas() {
  yield takeLatest(LOAD_BRANDS, safe(loadBrandsSaga));
  yield takeLatest(LOAD_BRANDS_MORE, safe(loadBrandsMoreSaga));
  yield takeLatest(UPDATE_BRAND, safe(updateBrandSaga));
  yield takeLatest(CREATE_BRAND, safe(createBrandSaga));
  yield takeLatest(DELETE_BRAND, safe(deleteBrandSaga));
}
