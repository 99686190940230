const createLine = (...args: (string | null | undefined)[]) =>
  args
    .filter(Boolean)
    .map((a) => a?.trim())
    .join(", ");
const handleAddress = (location: any) => {
  const { address, address2, city, region, postcode, country } = location;

  return createLine(address, address2, city, region, postcode, country);
};
export default handleAddress;
