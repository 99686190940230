import React, { useState } from "react";
import {
  IonAvatar,
  IonCheckbox,
  IonChip,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
} from "@ionic/react";
import avatarDefault from "../../../../../assets/images/avatar-default.svg";
import { EmployeeTransfer } from "../../../../../types/DBTypes";
import {
  formatAmount,
  formatDateTime,
  handleImageUrl,
} from "../../../../../utils";
import Modal from "../../../../common/Modal/Modal";
import { useAppDispatch } from "../../../../../store/store";
import { repeatEmployeeTransfer } from "../../../../../store/actions/splits.actions";

const StripeLink = `https://dashboard.stripe.com/test/connect/transfers`;

interface TProps {
  transfer: EmployeeTransfer;
}

const TransferEmployee = ({ transfer }: TProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id, employee, amount, status, createdAt } = transfer;
  const fullName = employee?.firstName + " " + employee?.lastName;
  const avatar = employee?.picture
    ? handleImageUrl(employee?.picture)
    : avatarDefault;

  const call = useAppDispatch();
  const isSuccessful = status === "sent";

  const handleModalClick = () => {
    if (isSuccessful) return window.open(`${StripeLink}/${id}`, "_blank");

    call(repeatEmployeeTransfer({ id }));
  };

  return (
    <>
      <IonItem onClick={() => setIsModalOpen(true)}>
        <IonAvatar slot="start">
          <img alt={fullName} src={avatar} />
        </IonAvatar>
        <IonLabel>
          <h3>{fullName}</h3>
        </IonLabel>
        <IonChip color={!isSuccessful ? "danger" : "success"}>
          {formatAmount(amount / 100)}
        </IonChip>
      </IonItem>

      <Modal
        image={avatar}
        primaryButton={{
          title: isSuccessful ? "Open Stripe" : "Retry transaction",
          onClick: handleModalClick,
        }}
        active={isModalOpen}
        setActive={setIsModalOpen}
      >
        <IonList>
          <IonListHeader>
            <h2>Transfer to {fullName}</h2>
          </IonListHeader>
          <IonItem>
            <IonLabel>Transaction id:</IonLabel>
            <IonLabel slot="end">{id}</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Status:</IonLabel>
            <IonLabel slot="end">{status}</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Date:</IonLabel>
            <IonLabel slot="end">{formatDateTime(createdAt)}</IonLabel>
          </IonItem>
        </IonList>
      </Modal>
    </>
  );
};

export default TransferEmployee;
